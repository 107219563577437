@import 'variables';

header {

	nav.navbar {
		font-family: $oscia-font;
		margin-bottom: 20px;

		a.selected-language {
			color: $oscia-green !important;
			font-weight: bold;
		}

		a.help {
			background-color: $oscia-green !important;
		}

		.header_logo {
			max-width: none !important;
		}

		a.nav-link {
			cursor: pointer;
		}
	}


	.environment-info {
		display: none;
		font-size: 0.7rem;
		text-align: center;

		a {
			color: black;
		}

		&.Local,
		&.Dev,
		&.Test,
		&.Beta,
		&.UAT {
			display: block;
		}

		&.Local {
			background: transparentize($color: burlywood, $amount: 0.3);
		}

		&.Dev {
			background: transparentize($color: palegoldenrod, $amount: 0.3);
		}

		&.Test {
			background: transparentize($color: lightsalmon, $amount: 0.3);
		}

		&.Beta {
			background: transparentize($color: lavender, $amount: 0.3);
		}

		&.UAT {
			background: transparentize($color: lightblue, $amount: 0.3);
		}
	}

	.navbar-brand {
		color: $oscia-green !important;
		font-weight: bold;
		font-family: 'Merriweather', serif;
		font-size: 20px;
	}

	.navbar-light .navbar-nav .nav-link {
		// color: $oscia-green !important;
		font-family: $oscia-font;
	}

	.navbar-light .navbar-nav .nav-link:hover {
		color: $oscia-yellow !important;
	}

	.navbar-brand {
		margin-left: 20px;
	}

	.navbar-brand:hover {
		color: $oscia-green !important;
	}

	.logo-img {
		max-width: 84px;
	}

	.container {
		max-width: 1400px;
	}

	.navbar-nav {
		flex-direction: inherit !important;
	}

	.nav-item {
		padding-left: 10px;
		padding-right: 10px;
	}

}