body {
	background-image: url('./FrontEnd-Bg-Image.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;

    /*
    This was interfering with button layouts
    Note:  should never reference #root, that's part of the demo 
    Should reference .front-end.layout, or .front-end as this component's root container

    #root{
        display:flex;
        min-height:100vh;
        flex-direction:column;
        div:first-of-type{
            flex:1;
        }
    }
    */
}
