footer.rainbowFooter{
    margin-top: 20px;
    padding-bottom: 0px;
    //height: 100px; 
    flex: 0 1 40px;
    .col{
        padding:25px;
        p{
            color:white;
            font-size:18px;
            font-weight:700;
            a{
                font-weight:600;
            }
        } 
        b{
            font-size:20px;
            color:white;
        }
        div{
            button{
                padding:7px;
                border:3px solid white;
                border-radius: 6px;
                color:white;
                background: rgba(0,0,0,0.2);
            }
        }
    }
}