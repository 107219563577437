.front-end{
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height:100vh;
}

.front-end .header{
    flex: 0 1 auto;
}

.front-end > div{
    flex: 1 1 auto;
}

.front-end .footer{
    flex: 0 1 auto;
}