.help.page {




	.faq {
		width:90%;
		display:block;
		margin:0 auto;
		margin-bottom:1.5rem;

	}

}

.answerPanel{
	color:white;
	border-radius: 0 0 8px 8px;
	border:none;
}

section.panel {
	background-color: #66A9A3;
	color:white;
	border-radius: 8px;
	border:none;
	
	h4{
		font-size: 16px;
		padding:0.7rem;
		margin:0;
		width:95%;
		font-family: "Montserrat", sans-serif !important;
		font-weight:500;
		cursor: pointer;
	}
	p.plus{
		font-weight: bold;
		font-size:25px;
		margin:0;
		float:right;
		cursor: pointer;
	}
	p.minus{
		font-weight: bold;
		font-size:30px;
		margin:0;
		float:right;
		margin-left:3px; //to match alignment of the +
		cursor: pointer;
	}
	div.toggleContainer{
		display:flex;
	}

}

p.content{
	background-color:beige;
	color:#2E5D59;
	padding:1rem;
	border-radius: 0px 0px 8px 8px;
	margin:0;
	animation: fade-in-keyframes 0.5s;
	transform-origin: top;

}
@keyframes fade-in-keyframes {
	from {
		transform: scaleY(0);
	}
	to { 
		transform:scaleY(1);
	}
}