#loading-page {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;

	background-color: transparentize($color: lightgray, $amount: 0.5);
	text-align: center;

	h1 {
		margin-top: 20%
	}

	.loading-spinner {
		display: inline-block;
		height: 5vh;
		animation: spin 2s infinite linear;
	}

}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}