$oscia-green: #007066;
$oscia-green-transparent: #007067b8;
$oscia-yellow: #FFDF1C;
$oscia-background: url(/images/Bg-Image.png);
$oscia-header-font: 'Merriweather', serif;
$oscia-font: 'Montserrat', sans-serif;
$oscia-white: #fff;
$oscia-subtitles: #F7EFDB;
$oscia-green-footer: #2E5D59;

$rainbow1: #318558b8;
$rainbow2: #86AA3Fb8;
$rainbow3: #C2C52Eb8; 

$control-disabled-grey: silver;